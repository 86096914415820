module.exports = () => {
  const nav = document.querySelector("body > header nav");
  if (!nav) return;
  const button = document.querySelector("body > header button");
  if (!button) return;
  const links = Array.from(nav.querySelectorAll("a:not(.custom-logo-link)"));
  button.addEventListener("click", () => {
    const logo = nav.querySelector("a.custom-logo-link");
    let animation = false;
    let closedHeight = 0;
    let openHeight = 0;
    const animationOpts = { duration: 300, easing: "ease-in-out" };
    if (links.length || !logo) {
      animation = true;
      links.forEach((link) => {
        openHeight += link.getBoundingClientRect().height;
      });
      closedHeight = logo.getBoundingClientRect().height;
      openHeight += closedHeight;
    }
    const closed = nav.classList.contains("closed");
    if (closed) {
      nav.classList.remove("closed");
      if (animation) {
        nav.animate(
          [{ height: closedHeight + "px" }, { height: openHeight + "px" }],
          animationOpts
        );
        links.forEach((link) => {
          link.animate(
            [
              {
                opacity: 0,
                visibility: "hidden",
                transform: "translateY(-2em)",
                pointerEvents: "none",
              },
              {
                opacity: 1,
                visibility: "visible",
                transform: "translateY(0)",
                pointerEvents: "all",
              },
            ],
            animationOpts
          );
        });
      }
    } else {
      nav.classList.add("closed");
      if (animation) {
        nav.animate(
          [{ height: openHeight + "px" }, { height: closedHeight + "px" }],
          animationOpts
        );
        links.forEach((link) => {
          link.animate(
            [
              {
                opacity: 1,
                visibility: "visible",
                transform: "translateY(0)",
                pointerEvents: "all",
              },
              {
                opacity: 0,
                visibility: "hidden",
                transform: "translateY(-2em)",
                pointerEvents: "none",
              },
            ],
            animationOpts
          );
        });
      }
    }
  });
  links.forEach((link) => {
    link.addEventListener("click", () => {
      if (
        link.hostname == location.hostname &&
        link.pathname == location.pathname &&
        innerWidth <= 800
      ) {
        button.click();
      }
    });
  });
};
