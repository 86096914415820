module.exports = () => {
  const lightbox = document.querySelector("#lightbox");
  if (!lightbox) return;

  let index = 0;
  // slide to function
  const slideTo = (i = 0, transition = true) => {
    const container = lightbox.querySelector(".gallery-container");
    const figs = Array.from(container.children);
    if (i < 0) i = figs.length - 1;
    if (i > figs.length - 1) i = 0;
    figs.forEach((fig, i2) => {
      if (!transition) {
        fig.style.transition = "none";
        setTimeout(() => {
          fig.style.transition = "";
        }, 500);
      }
      if (i == i2) {
        fig.classList.add("active");
      } else {
        fig.classList.remove("active");
      }
    });
    index = i;
  };

  // handle from the lower level gallery
  const openLightbox = (e) => {
    const btn = e.target;
    const i = parseInt(btn.getAttribute("data-index"));
    slideTo(i, false);
    lightbox.classList.remove("closed");
    document.body.style.overflow = "hidden";
  };

  const allBtnImgs = Array.from(
    document.querySelectorAll(
      "#singular .gallery-1-column button, #singular .gallery-3-column .col button"
    )
  );
  allBtnImgs.forEach((btn) => {
    btn.addEventListener("click", openLightbox);
  });

  // close button
  const close = document.querySelector("#lightbox .close");
  close.addEventListener("click", () => {
    lightbox.classList.add("closed");
    document.body.style.overflow = "";
    setTimeout(() => {
      index = 0;
    }, 1000);
  });

  // next button
  const next = document.querySelector("#lightbox .next");
  next.addEventListener("click", () => {
    slideTo(index + 1);
  });

  // prev button
  const prev = document.querySelector("#lightbox .prev");
  prev.addEventListener("click", () => {
    slideTo(index - 1);
  });

  // keyboard navigation
  addEventListener("keydown", (e) => {
    const galleryClosed = lightbox.classList.contains("closed");
    if (galleryClosed) return;
    if (e.key == "ArrowRight") {
      slideTo(index + 1);
    } else if (e.key == "ArrowLeft") {
      slideTo(index - 1);
    } else if (e.key == "Escape") {
      close.click();
    }
  });
};
