module.exports = () => {
  const galleryContainer = document.querySelector("#homepage-gallery");
  if (!galleryContainer) return;
  const figs = Array.from(galleryContainer.children);
  if (!figs) return;
  let index = 0;
  const slideGallery = () => {
    figs.forEach((fig, i) => {
      if (i == index) {
        fig.classList.add("active");
      } else {
        fig.classList.remove("active");
      }
    });
    index++;
    if (index > figs.length - 1) {
      index = 0;
    }
  };
  slideGallery();
  let galleryAnimation = setInterval(() => {
    slideGallery();
  }, 5000);
  galleryContainer.addEventListener("click", slideGallery);
  galleryContainer.addEventListener("pointerenter", () => {
    clearInterval(galleryAnimation);
  });
  galleryContainer.addEventListener("pointerleave", () => {
    galleryAnimation = setInterval(() => {
      slideGallery();
    }, 5000);
  });
};
